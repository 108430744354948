import *  as React from "react";
import {  useSelector, useDispatch } from "react-redux";
import { ApplicationState } from "../store/index";
import * as VerifyCheckStore from "../store/VerifyCheck";
import './Base.css';
import IAdoptSiteDraftRequest = VerifyCheckStore.IAdoptSiteDraftRequest;
import IAdoptSiteDraftResponse = VerifyCheckStore.IAdoptSiteDraftResponse;
import IVerifyCheckState = VerifyCheckStore.IVerifyCheckState;
import { Container, Row, Col } from "reactstrap";
import { NumericFormat } from "react-number-format";

interface IAdoptStart {
    actionCreators: typeof VerifyCheckStore.actionCreators,
    name: string;
}


const VerifyCheckAdoptStart: React.FunctionComponent<IAdoptStart> = (props) => {
    // configure local state
    const useState = React.useState;
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [dealerName, setDealerName] = useState("");
    const [assetTypeId, setAssetTypeId] = useState("1");
    const [vehicleVin, setVehicleVin] = useState("");
    const [vehicleYear, setVehicleYear] = useState("");
    const [vehicleMake, setVehicleMake] = useState("");
    const [vehicleModel, setVehicleModel] = useState("");
    const [vehicleMileage, setVehicleMileage] = useState("");
    const [vehicleColor, setVehicleColor] = useState("");
    const [tenderedCheckAmount, setTenderedCheckAmount] = useState("");

    //configure Redux
    const verifyCheckState = useSelector((state: ApplicationState) => state.verifyCheck);
    const dispatch = useDispatch();

    // event handlers
    const submitData = (e: React.MouseEvent<HTMLButtonElement>) => {

        const data = {
            loginId: verifyCheckState ? verifyCheckState.creditUnion.loginId : 0,
            siteDraftCheckId: verifyCheckState ? verifyCheckState.siteDraftCheck.siteDraftCheckId : 0,
            dealerContactFullName: fullName,
            dealerContactEmail: email,
            dealerContactPhone: phone,
            dealerName: dealerName,
            vehicleAssetTypeId: parseInt(assetTypeId),
            vehicleVin: vehicleVin,
            vehicleYear: vehicleYear,
            vehicleMake: vehicleMake,
            vehicleModel: vehicleModel,
            vehicleMileage: vehicleMileage,
            vehicleColor: vehicleColor,
            tenderedCheckAmount: tenderedCheckAmount
        } as IAdoptSiteDraftRequest;

        fetch(`api/adoptcheck`,
                {
                    method: 'POST', // *GET, POST, PUT, DELETE, etc.
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: { 'Content-Type': 'application/json' },
                    redirect: 'follow', // manual, *follow, error
                    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                    body: JSON.stringify(data) // body data type must match "Content-Type" header
                })
        .then(response => response.json() as Promise<IAdoptSiteDraftResponse>)
        .then(data => {
            dispatch({ type: 'RECEIVE_ADOPT_SITEDRAFT', data: data });

            if (data.isSuccess) {
                dispatch({ type: 'GOTO_PROCESS_STATE', processState: "adoptSuccess" });
            } else {
                dispatch({ type: 'GOTO_PROCESS_STATE', processState: "adoptFail" });
            }
        });
        dispatch({ type: 'REQUEST_ADOPT_SITEDRAFT' });
    };

    // component definition
    return <div>
        <div>Great, the final step it to Adopt this check for the purchase of the vehicle.</div>
        <div>By completing this process the check will be linked to the purchase of the vehicle.</div>
        <div>To Adopt this check we need to collect some information to complete the process.<br />Please enter the information requested below.</div>
        <Container>
            <Row>
               <Col xs="6">                                                                                                                         
                    <label>Dealer Representative Full Name</label>
                    <input className="form-control" name="sellerFullName" onChange={(ev: React.ChangeEvent<HTMLInputElement>,): void => setFullName(ev.target.value)} />
                    <label>Dealer Contact Email</label>
                    <input className="form-control" name="sellerEmail" onChange={(ev: React.ChangeEvent<HTMLInputElement>, ): void => setEmail(ev.target.value)} />
                    <label>Dealer Contact Phone</label>
                    <input className="form-control" name="sellerPhone" onChange={(ev: React.ChangeEvent<HTMLInputElement>, ): void => setPhone(ev.target.value)} />
                    <label>Dealership Name</label>
                    <input className="form-control" name="sellerDealershipName" onChange={(ev: React.ChangeEvent<HTMLInputElement>,): void => setDealerName(ev.target.value)} />
                </Col>
                <Col xs="6">                                                                                                                         
                    <label>Asset Type</label>
                    <select className="form-control" name="vehicleAssetType" onChange={(ev: React.ChangeEvent<HTMLSelectElement>,): void => setAssetTypeId(ev.target.value)} >
                        <option value="1">Private Passenger Auto</option>
                        <option value="2">Street Motorcycle</option>
                        <option value="3">Motorcycle, ATV, Jet Ski, Snowmobile</option>
                        <option value="4">Travel Trailer</option>
                        <option value="5">Motor Home</option>
                        <option value="6">Boat</option>
                    </select>
                    {(assetTypeId === "3" || assetTypeId === "4" || assetTypeId === "5" || assetTypeId === "6") ? (<label>Serial #</label>): (<label>VIN</label>)}
                    <input className="form-control" name="vehicleVin" onChange={(ev: React.ChangeEvent<HTMLInputElement>,): void => setVehicleVin(ev.target.value)} />
                    {(assetTypeId === "3" || assetTypeId === "4" || assetTypeId === "5" || assetTypeId === "6") && (
                        <React.Fragment>
                            <label>Year</label>
                            <input className="form-control" name="vehicleYear" onChange={(ev: React.ChangeEvent<HTMLInputElement>,): void => setVehicleYear(ev.target.value)} />
                            <label>Make</label>
                            <input className="form-control" name="vehicleMake" onChange={(ev: React.ChangeEvent<HTMLInputElement>,): void => setVehicleMake(ev.target.value)} />
                            <label>Model</label>
                            <input className="form-control" name="vehicleModel" onChange={(ev: React.ChangeEvent<HTMLInputElement>,): void => setVehicleModel(ev.target.value)} />
                        </React.Fragment>)}
                    {(assetTypeId === "1" || assetTypeId === "2" ) && (
                        <React.Fragment>
                            <label>Mileage</label>
                            <input className="form-control" name="vehicleMileage" onChange={(ev: React.ChangeEvent<HTMLInputElement>,): void => setVehicleMileage(ev.target.value)} />
                        </React.Fragment>)}
                    
                    <label>Color</label>
                    <input className="form-control" name="vehicleColor" onChange={(ev: React.ChangeEvent<HTMLInputElement>,): void => setVehicleColor(ev.target.value)} />
                    <label>Check Amount</label>
                    {/*<input className="form-control" name="tenderedCheckAmount" type="number" onChange={(ev: React.ChangeEvent<HTMLInputElement>,): void => setTenderedCheckAmount(ev.target.value)} />*/}
                    <NumericFormat className="form-control" name="tenderedCheckAmount" thousandSeparator={true} prefix={"$"} decimalScale={2} fixedDecimalScale={true} onValueChange={(values: any) => { const { value } = values; setTenderedCheckAmount(value); }} />
                    <div>&nbsp;</div>
                    <div className="button_container">
                        <button className="btn btn-primary" name="btnSubmit" value="adoptSuccess" onClick={submitData} >Submit</button>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>;
};

VerifyCheckAdoptStart.defaultProps = {actionCreators: VerifyCheckStore.actionCreators, name: "Mike" };

export default VerifyCheckAdoptStart as any;